import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { urlFor } from "../client";

const ImageHandler = ({ images, title }) => {
  if (!images || !images.length) return null;
  if (images.length === 1)
    return (
      <a
        key={images[0].url}
        target="_blank"
        href={images[0].url}
        rel="noreferrer"
      >
        <img
          className="h-auto w-100 object-cover"
          src={images[0].url}
          alt={`${title}`}
        />
      </a>
    );
  return (
    <Carousel className="border">
      {images.map((image) => {
        return (
          <div className="" key={image.url}>
            <img className="" alt={`${title}`} src={urlFor(image.url)} />
            <p className="bg-kb_dark legend">{title}</p>
          </div>
        );
      })}
    </Carousel>
  );
};

const SingleProject = ({ images, title, description, cb }) => {
  return (
    <main className="mb-24">
      <h1
        onClick={cb}
        className="cursor-pointer text-kb_blue text-3xl font-bold my-4"
      >
        {"<"} Tilbake
      </h1>
      <div className="w-full my-4">
        <ImageHandler images={images} title={title} />
      </div>
      <h1 className="text-4xl font-semibold text-kb_dark mt-2">{title}</h1>
      <p className="text-kb_gray text-lg">{description}</p>
    </main>
  );
};

export default SingleProject;
