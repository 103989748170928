import React from "react";

import { PortableText } from "@portabletext/react";

import envImage from "../imgs/env.png";
import qualityImage from "../imgs/quality.png";
import hseImage from "../imgs/worker.png";

const icons = [
  {
    src: envImage,
    value: "env",
  },
  {
    src: qualityImage,
    value: "quality",
  },
  {
    src: hseImage,
    value: "worker",
  },
];

const defaultPortableTextComponents = {
  block: {
    // Map block style "normal" to a <p>
    normal: ({ children }) => <p className="my-2">{children}</p>,
    // Map block style "h2" to <h2>
    h2: ({ children }) => (
      <h2 className="text-2xl font-bold mt-4 mb-2">{children}</h2>
    ),
    // Map block style "h3" to <h3> -- you can add more
    h3: ({ children }) => (
      <h3 className="text-xl font-semibold mt-3 mb-1">{children}</h3>
    ),
  },
  list: {
    // Map "bullet" lists to <ul>
    bullet: ({ children }) => (
      <ul className="list-disc list-inside my-2">{children}</ul>
    ),
    // Map "number" lists to <ol>
    number: ({ children }) => (
      <ol className="list-decimal list-inside my-2">{children}</ol>
    ),
  },
  listItem: {
    // Map list items in bullet lists
    bullet: ({ children }) => <li className="ml-4">{children}</li>,
    // Map list items in numbered lists
    number: ({ children }) => <li className="ml-4">{children}</li>,
  },
  marks: {
    // Map bold text
    strong: ({ children }) => <strong className="font-bold">{children}</strong>,
    // Map italic text
    em: ({ children }) => <em className="italic">{children}</em>,
    // Map underline
    underline: ({ children }) => <span className="underline">{children}</span>,
    // Map links
    link: ({ value, children }) => {
      const target = (value?.href || "").startsWith("http")
        ? "_blank"
        : undefined;
      return (
        <a
          href={value?.href}
          target={target}
          rel={target === "_blank" ? "noopener noreferrer" : undefined}
          className="text-blue-600 hover:underline"
        >
          {children}
        </a>
      );
    },
    // Optionally define other marks such as "code", "sup", "sub", etc.
  },
};

const Card = ({ icon, cardHeader, blocks }) => {
  return (
    <div className="rounded h-auto w-full bg-kb_white flex flex-col mb-6 p-6 lg:mb-0 lg:w-1/4 lg:p-10">
      <div className="flex flex-row mb-6 h-24 ">
        <div className="w-1/6">
          <img
            width="64"
            height="64"
            className="w-full h-auto"
            alt={`${cardHeader} logo`}
            src={icons.find((ic) => ic.value === icon).src}
          />
        </div>
        <h1 className="w-5/6 text-3xl text-kb_dark font-bold text-center break-words">
          {cardHeader}
        </h1>
      </div>
      {/* <div className="h-5/6 text-kb_gray font-light">{cardText}</div> */}
      <div className="h-5/6 text-kb_gray font-light prose">
        <PortableText
          value={blocks}
          components={defaultPortableTextComponents}
        />
      </div>
    </div>
  );
};

export default Card;
