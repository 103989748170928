import "./home.css";
import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import Card from "../components/Card";
import cert1image from "../imgs/cert 1.png";
import cert2Image from "../imgs/cert 2.png";
import ContactForm from "../components/ContactForm";

const Home = () => {
  const [valuesData, setValuesData] = useState([]);
  useEffect(() => {
    const fetchSanityData = async () => {
      const data = await sanityClient.fetch(`*[_type == "values"]`);
      setValuesData(data);
    };
    fetchSanityData();
  }, []);
  console.log(valuesData);

  return (
    <main className="bg-kb_light min-h-screen">
      <section className="home-main-banner flex relative">
        <div className="text-kb_white absolute w-4/5 top-24 left-12 lg:left-24 lg:w-2/5">
          <p className="font-bold text-right lg:text-left leading-tight tracking-wide text-2xl lg:text-5xl">
            GRAVING, SPRENGNING OG PROSJEKTERING OVER HELE ØSTLANDET
          </p>
          <div className="flex mt-6 justify-end lg:justify-start">
            <img
              width="96"
              height="96"
              className="h-100 w-100 lg:w-100 lg:h-100"
              src={cert1image}
              alt="ansvarsrettsertifisering"
            />
            <img
              width="96"
              height="96"
              className="ml-3 h-100 w-100 lg:w-100 lg:h-100"
              src={cert2Image}
              alt="miljøfyrtårnsertifisering"
            />
          </div>
        </div>
      </section>
      <section
        style={{ minHeight: "70vh" }}
        className="flex justify-center items-center"
      >
        <div style={{ maxWidth: "1010px" }} className="w-full m-auto px-4">
          <div
            style={{ width: "fit-content" }}
            className="bg-kb_blue rounded self-start px-3 py-1"
          >
            <span className="text-kb_white ">VÅR VISJON</span>
          </div>
          <h1 className="text-3xl lg:text-4xl font-semibold text-kb_dark">
            Med kontinuerlig utvikling jobber vi bedre og smartere
          </h1>
          <p className="text-kb_gray">
            Knut Bjerke AS ble eteablert i 1958 og er i dag et av de større
            entrepenørselskapene innen grunnarbeider i Østfold og søndre del av
            Akershus.
            <br />
            <br />
            Vi henvender oss for det meste mot det profesjonelle markedet med
            hovedvekt på kommunalteknisk anlegg, VVA arbeider og grunnarbeider
            for næringsbygg og offentlige bygg, infrastruktur for boligfelt. Vi
            kan vise til en rekke store og prestisjefulle oppdrag på vår
            referanseliste
          </p>
        </div>
      </section>
      <section className="bg-kb_blue flex flex-col items-center h-auto w-full ">
        <h1 className="text-3xl w-full text-center text-kb_white font-bold mb-16 mt-20 lg:mt-28 ">
          VÅRE VERDIER
        </h1>
        <div className="h-100 flex flex-col mb-24 w-full justify-between lg:flex-row lg:justify-around ">
          {valuesData.map((value) => (
            <Card
              key={value._id}
              cardHeader={value.title}
              icon={value.icon}
              blocks={value.body}
            />
          ))}
        </div>
      </section>
      <ContactForm />
    </main>
  );
};

export default Home;
